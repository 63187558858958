import React from 'react'
import { Link } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'

const CambioIdioma = () => {
  const { languages, changeLanguage } = useI18next()
  return (
    <article className='absolute top-7 right-5 md:top-10 md:right-20'>
      <ul className=' flex justify-center items-center space-x-3'>
        {languages.map((lng, index) => (
          <li
            className='border-r-2 pr-3 border-white last-of-type:border-0'
            key={index}
          >
            <Link
              to='/'
              key={lng}
              onClick={(e) => {
                e.preventDefault()
                changeLanguage(lng)
              }}
              className='text-white text-lg hover:opacity-70 transition-all duration-100 font-bold'
            >
              {lng}
            </Link>
          </li>
        ))}
      </ul>
    </article>
  )
}

export default CambioIdioma
