import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Cookies = ({ handleCookiesModal }) => {
  const { t } = useTranslation()

  return (
    <div className='fixed container top-10 bottom-10 w-10/12 md:w-1/2 md:h-3/5 md:top-1/4  md:bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-azul md:p-20 rounded-3xl shadow-lg p-5 z-30 border border-white'>
      <div className='p-5 text-sm flex flex-col text-white overflow-auto'>
        <h1 className='underline font-bold  text-2xl md:text-3xl'>
          {t('cookiesModal.titulo')}
        </h1>
        <h2 className=' text-2xl md:text-3xl mb-3'>{t('cookiesModal.t1')}</h2>
        <div dangerouslySetInnerHTML={{ __html: t('cookiesModal.p1') }} />
        <h2 className='font-bold mt-2'>{t('cookiesModal.t2')}</h2>
        <p> {t('cookiesModal.p2')} </p>
        <h2 className='font-bold mt-2'>{t('cookiesModal.t3')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('cookiesModal.p3') }} />
        <h2 className='font-bold mt-2'>{t('cookiesModal.t4')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('cookiesModal.p4') }} />
        <h2 className='font-bold mt-2'>{t('cookiesModal.t5')}</h2>
        <p
          className=' mb-10'
          dangerouslySetInnerHTML={{ __html: t('cookiesModal.p5') }}
        />
        <button
          className='absolute top-2 right-4 md:top-10 md:right-10 font-bold text-3xl '
          onClick={handleCookiesModal}
        >
          x
        </button>
      </div>
    </div>
  )
}

export default Cookies
