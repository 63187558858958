import React from 'react'
import { useTranslation } from 'react-i18next'

const Cookies = ({ handleCookiesModal, agregarCookie, noAgregarCookie }) => {
  const { t } = useTranslation()

  const verInfo = () => {
    handleCookiesModal()
  }
  return (
    <>
      <div className='fixed bottom-0 left-0 right-0 flex justify-center items-center z-50 bg-blanco bg-opacity-90 h-80 sm:h-48 bg-azul'>
        <div className='container p-5 sm:flex flex-row sm:justify-center items-center sm:space-x-5  text-white'>
          <p
            className='text-sm sm:w-7/12 lg:text-base lg:font-light '
            dangerouslySetInnerHTML={{ __html: t('avisoCookies.mensaje') }}
          ></p>

          <div className='flex space-x-2 sm:flex-col sm:space-x-0 items-center justify-center'>
            <button
              className='flex justify-center w-full items-center text-xs px-5 py-3 mt-3 font-medium rounded-lg shadow border-2 border-negro bg-gris hover:bg-white hover:text-azul transition cursor-pointer'
              onClick={agregarCookie}
            >
              {t('avisoCookies.aceptar')}
            </button>
            <button
              className='flex justify-center w-full items-center text-xs px-5 py-3 mt-3 font-medium rounded-lg shadow border-2 border-negro bg-gris hover:bg-white hover:text-azul transition cursor-pointer'
              onClick={noAgregarCookie}
            >
              {t('avisoCookies.rechazar')}
            </button>
            <button
              className='flex justify-center w-full items-center text-xs px-5 mt-3 font-medium cursor-pointer hover:underline'
              onClick={verInfo}
            >
              {t('avisoCookies.masInfo')}
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cookies
