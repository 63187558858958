import React from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../../assets/logo.svg'

const Aside = () => {
  const { t } = useTranslation()
  return (
    <section
      className='flex flex-col justify-center items-start lg:w-1/2 px-4 md:px-10'
      id={t('id.contacto')}
    >
      <h2 className='text-azul text-4xl md:text-5xl border-b border-azul pb-5 mb-5 font-titulo'>
        {t('contacto.presentacion')}
      </h2>

      <Logo alt='technonavalia Logo' className='w-48 md:w-54 h-20' />
      <ul
        dangerouslySetInnerHTML={{ __html: t('contacto.direccion') }}
        className='text-base'
      />

      {/* GOOGLE MAPS  */}
      <article className='w-full mt-10 rounded-xl overflow-hidden'>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2954.5117054226785!2d-8.750120784547978!3d42.2248797791962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2f620283ca42df%3A0xd56eeaed218cc717!2sAv.%20da%20Beiramar%2C%20211%2C%2036208%20Vigo%2C%20Pontevedra!5e0!3m2!1ses!2ses!4v1652803843224!5m2!1ses!2ses'
          style={{ border: 0 }}
          allowFullScreen
          loading='lazy'
          className='w-full h-96'
          title='localizacion'
        ></iframe>
      </article>
    </section>
  )
}

export default Aside
