import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
const Nosotros = ({ titulo, texto, identificador }) => {
  const { t } = useTranslation()
  return (
    <>
      <section
        className='hidden lg:flex flex-col items-center justify-center  bg-[#1b273c]'
        id={identificador}
      >
        <article className='grid grid-cols-2 items-center container'>
          <div className=' rounded-3xl  m-10 drop-shadow   text-white'>
            <h2 className='font-titulo text-5xl mb-3'>{t(titulo)}</h2>
            <p className='md:text-xl'>{t(texto)}</p>
          </div>
          <div className=' h-full overflow-hidden'>
            <StaticImage
              src='../images/nosotros.jpg'
              alt='descriptivo nosotros'
              className='h-full w-full'
            />
          </div>
        </article>
      </section>

      {/* COMPONENTE SMARTHPHONE */}

      <section
        className='flex flex-col items-center justify-center lg:hidden p-8 bg-[#1b273c] text-white'
        id={identificador}
      >
        <article className='flex flex-col items-center justify-center  '>
          <div className=' z-10 '>
            <h2 className='font-titulo text-4xl  mb-4'>{t(titulo)}</h2>
            <div className=' h-[200px] md:h-[350px] overflow-hidden mb-6 '>
              <StaticImage
                src='../images/nosotros.jpg'
                alt='nosotros'
                className='h-full rounded-lg '
              />
            </div>
            <p>{t(texto)}</p>
          </div>
        </article>
      </section>
    </>
  )
}

export default Nosotros
