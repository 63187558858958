import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import Navbar from './Navbar'
import CambioIdioma from './CambioIdioma'
import Logo from '../assets/logoinv.svg'

const Hero = ({ verNavbar }) => {
  const { t } = useTranslation()
  return (
    <header
      className='relative flex flex-row items-center justify-center h-80 md:h-[700px] overflow-hidden '
      id={t(`id.home`)}
    >
      <StaticImage
        src='../images/bghero.jpg'
        alt='Hero Background'
        placeholder='tracedSVG'
        className='object-cover w-full h-full'
        quality={100}
      />
      <CambioIdioma />
      <article className='absolute w-full flex flex-col items-center justify-center p-5 md:p-0 '>
        <Logo className='w-full md:h-20 ' />
        <h1 className='  text-white text-xl md:text-3xl md:mt-4 text-center'>
          {t('hero')}
        </h1>
        <h2 className='text-white md:text-xl mt-2 uppercase'>{t('herosub')}</h2>
      </article>
      <Navbar verNavbar={verNavbar} />
    </header>
  )
}

export default Hero
