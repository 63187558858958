import React, { useState, useEffect } from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import './contactform.css'

const ContactForm = () => {
  const { t } = useTranslation()

  useEffect(() => {
    window.addEventListener('click', handleModales)
  }, [])

  const [modalErrorEnvio, setModalErrorEnvio] = useState(false)
  const [modalErrores, setModalErrores] = useState(false)
  const [verFlecha, setVerFlecha] = useState('hidden')
  const [textoModalErrores, setTextoModalErrores] = useState('')
  const [modalEnviado, setModalEnviado] = useState(false)
  const [desbloqueo, setDesbloqueo] = useState(false)
  const [formulario, setFormulario] = useState({
    nombre: '',
    telefono: '',
    email: '',
    asunto: '',
    comentario: '',
    privacidad: 'He leído y acepto la política de privacidad.',
  })

  const handleChange = (e) => {
    setFormulario({
      ...formulario,
      [e.target.name]: e.target.value,
    })
  }
  const handleDesbloqueo = () => {
    if (verFlecha === 'hidden') {
      setVerFlecha('')
    } else {
      setVerFlecha('hidden')
    }
    setDesbloqueo(!desbloqueo)
  }

  const handleModales = () => {
    setModalErrores(false)
    setModalErrorEnvio(false)
    setModalEnviado(false)
  }

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
      )
      .join('&')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (formulario.nombre.length < 3) {
      setTextoModalErrores(t('formulario.errorNombre'))
      setModalErrores(true)
      return
    }
    if (formulario.telefono.length < 5) {
      setTextoModalErrores(t('formulario.errorTel'))
      setModalErrores(true)
      return
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': e.target.getAttribute('name'),
        ...formulario,
      }),
    })
      .then(
        () =>
          setFormulario({
            nombre: '',
            telefono: '',
            email: '',
            asunto: '',
            comentario: '',
            privacidad: 'He leído y acepto la política de privacidad.',
          }),
        setModalEnviado(true)
      )
      .catch((error) => setModalErrorEnvio(true))
  }

  return (
    <section className='flex flex-col items-center justify-start mt-5 md:mt-0'>
      <article className='p-5 md:border-t-4 rounded-lg border-azul'>
        <form
          name='contact'
          method='post'
          onSubmit={handleSubmit}
          data-netlify='true'
          data-netlify-honeypot='bot-field'
          className='formulario max-w-xl mx-auto'
        >
          <input type='hidden' name='form-name' value='contact' />
          <p hidden>
            <label>
              Don’t fill this out: <input name='bot-field' />
            </label>
          </p>

          <div className='relative z-0 mb-6 w-full group'>
            <input
              type='text'
              value={formulario.nombre}
              onChange={handleChange}
              name='nombre'
              id='nombre'
              autoComplete='off'
              required
              className='peer'
              placeholder=' '
            />
            <label
              htmlFor='nombre'
              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-azul peer-focus:dark:text-azul peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
            >
              {t('formulario.nombre')}
            </label>
          </div>

          <div className='relative z-0 mb-6 w-full group'>
            <input
              type='tel'
              value={formulario.telefono}
              onChange={handleChange}
              name='telefono'
              id='telefono'
              autoComplete='off'
              required
              className='peer'
              placeholder=' '
            />
            <label
              htmlFor='telefono'
              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-azul peer-focus:dark:text-azul peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
            >
              {t('formulario.telefono')}
            </label>
          </div>

          <div className='relative z-0 mb-6 w-full group'>
            <input
              type='email'
              value={formulario.email}
              onChange={handleChange}
              name='email'
              id='email'
              autoComplete='off'
              className='peer'
              placeholder=' '
            />
            <label
              htmlFor='email'
              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-azul peer-focus:dark:text-azul peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
            >
              {t('formulario.email')}
            </label>
          </div>

          <div className='relative z-0 mb-6 w-full group'>
            <input
              type='text'
              value={formulario.asunto}
              onChange={handleChange}
              name='asunto'
              id='asunto'
              autoComplete='off'
              required
              className='peer'
              placeholder=' '
            />
            <label
              htmlFor='asunto'
              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-azul peer-focus:dark:text-azul peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
            >
              {t('formulario.asunto')}
            </label>
          </div>

          <div className='relative z-0 mb-6 w-full group mt-2'>
            <textarea
              name='comentario'
              id='comentario'
              value={formulario.comentario}
              onChange={handleChange}
              maxLength='500'
              rows='5'
              className='peer'
              placeholder=' '
            />
            <label
              htmlFor='comentario'
              className='absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-azul peer-focus:dark:text-azul peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6'
            >
              {t('formulario.comentario')}
            </label>
          </div>

          {/* ACEPTACION */}

          <div>
            <div className='flex items-center mr-4 mb-2'>
              <input
                type='checkbox'
                id='A3-yes'
                name='A3-confirmation'
                onChange={() => handleDesbloqueo()}
                value='yes'
                className='opacity-0 absolute h-5 w-5'
              />
              <div className='bg-white border-2 rounded border-azul w-5 h-5 flex flex-shrink-0 justify-center items-center mr-3 focus-within:border-azul'>
                <svg
                  className={`fill-current ${verFlecha} w-3 h-3 text-azul pointer-events-none`}
                  version='1.1'
                  viewBox='0 0 17 12'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g fill='none' fillRule='evenodd'>
                    <g
                      transform='translate(-9 -11)'
                      fill='#3c3c3b'
                      fillRule='nonzero'
                    >
                      <path d='m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z' />
                    </g>
                  </g>
                </svg>
              </div>
              <label
                htmlFor='A3-yes'
                className='select-none text-xs text-gris opacity-80 mt-5'
              >
                {t('formulario.formlegal')} &nbsp;
              </label>
            </div>
            <button
              type='submit'
              disabled={!desbloqueo}
              className={
                desbloqueo === true
                  ? 'bg-azul text-white border border-azul rounded-full text-base mt-4  px-7 py-2 transition-all duration-400 hover:text-blanco hover:scale-105 ml-8'
                  : 'opacity-40 bg-white text-azul border border-azul rounded-full text-base mt-4 px-7 py-2'
              }
            >
              {desbloqueo ? t('formulario.aceptado') : t('formulario.aceptar')}
            </button>
          </div>

          {/* -------------- */}

          {modalErrores && (
            <p className='text-xs p-4 rounded-full bg-red-500 text-white mt-7 text-center'>
              {textoModalErrores}
            </p>
          )}
          {modalErrorEnvio && (
            <p className='text-xs p-4 rounded-full bg-red-500 text-white mt-7 text-center'>
              {t('formulario.errorEnvio')}
            </p>
          )}
          {modalEnviado && (
            <p className='text-xs p-4 rounded-full bg-green-500 text-white mt-7 text-center'>
              {t('formulario.enviado')}
            </p>
          )}
        </form>
      </article>
    </section>
  )
}

export default ContactForm
