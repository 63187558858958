import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import Logo from '../assets/logocopyinv.svg'
import Syntelix from '../assets/syntelix.svg'

const Footer = ({
  handlePrivacidad,
  handleCookiesModal,
  handleLegal,
  handleCalidad,
}) => {
  const { t } = useTranslation()
  const fecha = new Date()

  return (
    <footer className='flex  bg-azul py-10 '>
      <article className='container flex flex-col items-start md:items-center  xl:flex-row xl:justify-between px-5 xl:px-20 '>
        <div className='flex flex-col items-start mb-5 xl:mb-0'>
          <div className='flex flex-col  items-start  md:flex-row md:items-center md:space-x-10 mb-2'>
            <Logo className='w-72 h-20 mb-5 md:mb-0' alt='Logo' />
            <StaticImage
              src='../images/iso.jpg'
              className='w-60 h-24'
              alt='ISO Logo'
            />
          </div>
        </div>

        <article className='flex flex-col items-start md:justify-center md:items-end'>
          <div className='flex flex-col md:flex-row items-start justify-center '>
            <button
              className=' text-white hover:opacity-70 p-0.5 md:p-0 md:border-r md:pr-3 md:ml-3'
              onClick={handlePrivacidad}
            >
              {t('footer.politica')}
            </button>
            <button
              className=' text-white hover:opacity-70 p-0.5 md:p-0 md:border-r md:pr-3 md:ml-3'
              onClick={handleCookiesModal}
            >
              {t('footer.cookiesModal')}
            </button>
            <button
              className=' text-white hover:opacity-70 p-0.5 md:p-0 md:border-r md:pr-3 md:ml-3'
              onClick={handleLegal}
            >
              {t('footer.legal')}
            </button>
            <button
              className=' text-white hover:opacity-70 p-0.5 md:p-0 md:ml-3'
              onClick={handleCalidad}
            >
              {t('footer.calidad')}
            </button>
          </div>
          <div className='flex flex-col justify-center  md:items-end  md:flex-row md:space-x-5 mt-3 md:mt-0'>
            <p className='text-white text-sm mb-2 '>
              {`Technonavalia © 2012 - ${fecha.getFullYear()}. `}
              {t('footer.oficial')}
            </p>
            <a
              href='https://www.syntelix.net/'
              target='_blank'
              rel='noopener noreferrer'
              aria-label='Enlace al sitio web oficial de Syntelix'
            >
              <Syntelix className='w-28 h-10 mt-2' alt='Syntelix Logo' />
            </a>
          </div>
        </article>
      </article>
    </footer>
  )
}

export default Footer
