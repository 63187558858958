import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'
const Izquierda = ({ imagen, titulo, texto, lista = null, identificador }) => {
  const { t } = useTranslation()
  return (
    <>
      <section
        className='hidden lg:flex flex-col items-center justify-center container max-w-7xl'
        id={identificador}
      >
        <article className='grid grid-cols-2 items-center m-5 -translate-x-10'>
          <div className='bg-white bg-opacity-80 rounded-3xl translate-x-28 hover:translate-x-40 z-10 m-10 transition-all duration-200 transform-gpu drop-shadow p-16'>
            <h2 className='font-titulo text-4xl text-azul mb-3'>{t(titulo)}</h2>
            <p className='md:text-lg'>{t(texto)}</p>
            {lista ? (
              <ul
                className='list-disc text-sm md:text-lg font-bold text-azul mt-4'
                dangerouslySetInnerHTML={{ __html: t('tecnico.lista') }}
              />
            ) : null}
          </div>
          <div className=' h-[400px] overflow-hidden'>
            <GatsbyImage
              image={imagen}
              alt='descriptivo servicio'
              className=' rounded-3xl h-full '
            />
          </div>
        </article>
      </section>

      {/* COMPONENTE SMARTHPHONE */}

      <section
        className='flex flex-col items-center justify-center lg:hidden p-8'
        id={identificador}
      >
        <article className='flex flex-col items-center justify-center  '>
          <div className=' z-10 '>
            <h2 className='font-titulo text-4xl text-azul mb-4'>{t(titulo)}</h2>
            <div className=' h-[200px] md:h-[350px] overflow-hidden mb-6 '>
              <GatsbyImage
                image={imagen}
                alt='descriptivo servicio'
                className='h-full rounded-lg '
              />
            </div>
            <p>{t(texto)}</p>
            {lista ? (
              <ul
                className='list-disc text-sm font-bold text-azul mt-4'
                dangerouslySetInnerHTML={{ __html: t('tecnico.lista') }}
              />
            ) : null}
          </div>
        </article>
      </section>
    </>
  )
}

export default Izquierda
