import React from 'react'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

const Cookies = ({ handleCalidad }) => {
  const { t } = useTranslation()
  return (
    <div className='fixed container top-40 bottom-20 w-11/12 md:w-1/2 bg-white  md:top-2/5  md:bottom-0 left-0 right-0 flex flex-col items-center justify-center md:p-20 rounded-3xl shadow-lg  z-30 border border-white'>
      <div className='text-sm flex flex-col text-white'>
        {t('calidadImagen') === 'es' ? (
          <StaticImage
            src='../images/calidad.jpg'
            alt='politica de calidad'
            className='w-full h-full'
          />
        ) : (
          <StaticImage
            src='../images/calidadIngles.jpg'
            alt='politica de calidad'
            className='w-full h-full'
          />
        )}
        <button
          className='absolute top-6 right-4 md:top-10 md:right-10 font-bold text-3xl text-azul '
          onClick={handleCalidad}
        >
          x
        </button>
      </div>
    </div>
  )
}

export default Cookies
