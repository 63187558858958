import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Privacidad = ({ handlePrivacidad }) => {
  const { t } = useTranslation()

  return (
    <div className='fixed container top-10 bottom-10 w-10/12 md:w-1/2 md:h-2/5 md:top-1/4  md:bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-azul md:p-20 rounded-3xl shadow-lg p-5 z-30 border border-white'>
      <div className='p-5 text-sm flex flex-col overflow-auto'>
        <h2 className='underline font-bold text-white text-2xl md:text-3xl'>
          {t('privacidad.titulo')}
        </h2>
        <div
          className='mt-3 text-white'
          dangerouslySetInnerHTML={{ __html: t('privacidad.p1') }}
        />
        <p
          className='mt-3 text-white'
          dangerouslySetInnerHTML={{ __html: t('privacidad.p2') }}
        />
        <button
          className='absolute top-2 right-4 md:top-10 md:right-10 font-bold text-3xl text-white'
          onClick={handlePrivacidad}
        >
          x
        </button>
      </div>
    </div>
  )
}

export default Privacidad
