import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Legal = ({ handleLegal }) => {
  const { t } = useTranslation()

  return (
    <div className='fixed container top-10 bottom-10 w-10/12 md:w-1/2 md:h-3/5 md:top-1/4  md:bottom-0 left-0 right-0 flex flex-col items-center justify-center bg-azul md:p-20 rounded-3xl shadow-lg p-5 z-30 border border-white'>
      <div className='p-5 text-sm flex flex-col text-white overflow-auto'>
        <h1 className='text-gray font-bold underline pt-5 border-x-0 text-3xl mb-4'>
          {t('legal.aviso')}
        </h1>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.datos')}</h2>
        <p dangerouslySetInnerHTML={{ __html: t('legal.p1') }}></p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.usuarios')}</h2>
        <p> {t('legal.p2')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.uso')}</h2>
        <p> {t('legal.p3')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.proteccion')}</h2>
        <p> {t('legal.p4')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.propiedad')}</h2>
        <p> {t('legal.p5')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.exclusion')}</h2>
        <p> {t('legal.p6')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.modificaciones')}</h2>
        <p> {t('legal.p7')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.empleo')}</h2>
        <p> {t('legal.p8')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.enlaces')}</h2>
        <p> {t('legal.p9')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.derecho')}</h2>
        <p> {t('legal.p10')} </p>
        <h2 className='font-bold mt-2 mb-1'>{t('legal.generalidades')}</h2>
        <p> {t('legal.p11')} </p>
        <button
          className='absolute top-2 right-4 md:top-10 md:right-10 font-bold text-3xl '
          onClick={handleLegal}
        >
          x
        </button>
      </div>
    </div>
  )
}

export default Legal
