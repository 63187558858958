import React from 'react'
import Logos from '../assets/carrusel.svg'

const Carrusel = () => {
  return (
    <section className='relative flex items-center justify-center container h-52 overflow-hidden my-5 md:mt-20  scale-90 md:before:w-32 before:h-full before:left-0 before:bg-gradient-to-r from-white to-transparent before:block before:absolute before:z-10 md:after:w-32 after:h-full after:bg-gradient-to-l after:block after:absolute after:right-0 after:z-10'>
      <Logos
        className='absolute h-full left-0 animate-carrusel'
        alt='Logos Carroussel'
      />
    </section>
  )
}

export default Carrusel
