import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Hero from '../components/Hero'
import Nosotros from '../components/Nosotros'
import Izquierda from '../components/Izquierda'
import Derecha from '../components/Derecha'
import Aside from '../components/contacto/Aside'
import ContactForm from '../components/contacto/ContactForm'
import Footer from '../components/Footer'
import Carrusel from '../components/Carrusel'
import Privacidad from '../components/Privacidad'
import CookiesModal from '../components/CookiesModal'
import Cookies from '../components/Cookies'
import Legal from '../components/Legal'
import Calidad from '../components/Calidad'
import Flecha from '../assets/flecha.svg'
import Seo from '../components/Seo'

export default function Home({ data }) {
  const { t } = useTranslation()
  const { language } = useI18next()
  const comunicaciones = getImage(data.comunicaciones.childImageSharp)
  const equipo = getImage(data.equipo.childImageSharp)
  const avit = getImage(data.avit.childImageSharp)
  const tecnico = getImage(data.tecnico.childImageSharp)
  const [verPrivacidad, setVerPrivacidad] = useState(false)
  const [verCookies, setVerCookies] = useState(false)
  const [cookies, setCookies] = useState(true)
  const [verLegal, setVerLegal] = useState(false)
  const [verCalidad, setVerCalidad] = useState(false)
  const [flecha, setFlecha] = useState('opacity-0 invisible')
  const [verNavbar, setVerNavbar] = useState(
    'absolute flex flex-row items-center justify-center self-end mb-8 animate-from-bellow transition-all duration-500'
  )

  useEffect(() => {
    if (localStorage.getItem('Technonavalia Cookies ACEPTADAS')) {
      window.dataLayer.push({ event: 'cookies-aceptadas' })
      setCookies(false)
    } else if (localStorage.getItem('Technonavalia Cookies RECHAZADAS')) {
      setCookies(false)
    } else {
      setCookies(true)
    }
    window.addEventListener('scroll', handleScroll)
  }, [])

  const agregarCookie = () => {
    //Esta linea añade el evento en la capa superior que activa la etiqueta de GTM.
    window.dataLayer.push({ event: 'cookies-aceptadas' })
    localStorage.setItem('Technonavalia Cookies ACEPTADAS', true)
    setCookies(false)
  }

  const noAgregarCookie = () => {
    localStorage.setItem('Technonavalia Cookies RECHAZADAS', true)
    setCookies(false)
  }

  const handleScroll = () => {
    const position = window.pageYOffset
    if (position < 600) {
      setFlecha('opacity-0 invisible')
      setVerNavbar(
        'absolute flex flex-row items-center justify-center self-end mb-8 transition-all duration-500'
      )
    } else {
      setFlecha('visible opacity-100')
      setVerNavbar('fixed top-0  bg-azul w-full py-4 z-30')
    }
  }

  const handleCookiesModal = () => {
    setVerPrivacidad(false)
    setVerLegal(false)
    setVerCookies(!verCookies)
    setVerCalidad(false)
  }

  const handlePrivacidad = () => {
    setVerCookies(false)
    setVerLegal(false)
    setVerCalidad(false)
    setVerPrivacidad(!verPrivacidad)
  }

  const handleLegal = () => {
    setVerCookies(false)
    setVerPrivacidad(false)
    setVerCalidad(false)
    setVerLegal(!verLegal)
  }

  const handleCalidad = () => {
    setVerCookies(false)
    setVerPrivacidad(false)
    setVerLegal(false)
    setVerCalidad(!verCalidad)
  }

  const datos = {
    title: t('seo.title'),
    description: t('seo.description'),
    lang: language,
  }

  return (
    <main>
      <Seo datos={datos} />

      <section>
        <Hero verNavbar={verNavbar} />

        <Nosotros
          titulo='nosotros.titulo'
          texto='nosotros.texto'
          identificador={t('id.nosotros')}
        />
      </section>

      <Izquierda
        titulo='comunicaciones.titulo'
        texto='comunicaciones.texto'
        imagen={comunicaciones}
        identificador={t('id.comunicaciones')}
      />
      <Derecha
        titulo='equipo.titulo'
        texto='equipo.texto'
        imagen={equipo}
        identificador={t('id.navegacion')}
      />
      <Izquierda
        titulo='avit.titulo'
        texto='avit.texto'
        imagen={avit}
        identificador={t('id.avit')}
      />
      <Derecha
        titulo='tecnico.titulo'
        texto='tecnico.texto'
        imagen={tecnico}
        lista
        identificador={t('id.servicio')}
      />
      <Carrusel />
      <div className='flex flex-col items-center justify-center mt-4 sm:mt-0 p-7 lg:py-20'>
        <div className='container anchoMaximo flex flex-col items-center justify-center lg:flex-row lg:items-stretch  mx-auto'>
          <Aside />

          <ContactForm />
        </div>
      </div>
      {cookies ? (
        <Cookies
          handleCookiesModal={handleCookiesModal}
          agregarCookie={agregarCookie}
          noAgregarCookie={noAgregarCookie}
        />
      ) : null}
      {verPrivacidad ? (
        <Privacidad handlePrivacidad={handlePrivacidad} />
      ) : null}
      {verCookies ? (
        <CookiesModal handleCookiesModal={handleCookiesModal} />
      ) : null}
      {verLegal ? <Legal handleLegal={handleLegal} /> : null}
      {verCalidad ? <Calidad handleCalidad={handleCalidad} /> : null}

      <AnchorLink
        to={t(`ruta.home`)}
        className={`${flecha} fixed bottom-14 right-8 md:bottom-28 md:right-10 w-12 h-12 rounded-full border-white border-2 bg-azul flex items-center justify-center transition-all duration-500 z-40 hover:-translate-y-2`}
      >
        <Flecha className='w-1/2' />
      </AnchorLink>

      <Footer
        handlePrivacidad={handlePrivacidad}
        handleCookiesModal={handleCookiesModal}
        handleLegal={handleLegal}
        handleCalidad={handleCalidad}
      />
    </main>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    comunicaciones: file(relativePath: { eq: "comunicaciones.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    equipo: file(relativePath: { eq: "equipo.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    avit: file(relativePath: { eq: "avit.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
    tecnico: file(relativePath: { eq: "tecnico.jpg" }) {
      id
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
      }
    }
  }
`
